import "@fontsource/bowlby-one-sc";
import React from 'react';
import ReactDOM from 'react-dom/client';
import './resources/styles/index.scss';
import App from './app/app';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.Fragment>
      <App />
  </React.Fragment>
);
