import React, {RefObject, useEffect, useRef, useState} from 'react';
// @ts-ignore
import ModalVideo from 'react-modal-video';
import MailchimpSubscribe from "react-mailchimp-subscribe";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation
} from "react-router-dom";
import ReactGA from 'react-ga4';
import Sketch from '../resources/images/cop_robber_sketch.png';
import CopRobber from '../resources/images/cop_robber.png';
import BackgroundVideo from '../resources/videos/roco_hp_transition.mp4';
import Wireframes from '../resources/images/roco_welcome_wireframes.png';
import DonatePaypal from '../resources/images/paypal_button.png';
import IconBird from '../resources/images/icon_bird.png';

import ItemSixthSense from '../resources/images/items/sixth_sense.png';
import ItemTimeTravel from '../resources/images/items/time_travel.png';
import ItemCommunicationJammer from '../resources/images/items/communication_jammer.png';
import ItemStableBag from '../resources/images/items/stable_bag.png';
import ItemDoubleValue from '../resources/images/items/double_value.png'

import CharacterLewis from '../resources/images/lewis.png';
import CharacterLewisAndRusty from '../resources/images/lewis_rusty.png';
import CharacterRusty from '../resources/images/rusty.png';
import CharacterAbigail from '../resources/images/abi.png';
import CharacterDouglas from '../resources/images/douglas.png';
import CharacterFoxy from '../resources/images/foxy.png';
import CharacterVally from '../resources/images/vally.png';
import CharacterTess from '../resources/images/tess.png';
import CharacterChester from '../resources/images/chester.png';

import StarterPackageGem from '../resources/images/starter_package_gems.png';
import StarterPackageScore from '../resources/images/starter_package_score.png';

import ThumbnailLogo from '../resources/images/logo_transparent_purple.png';
import ThumbnailAdvertising from '../resources/images/press/small__background_illustration.png';
import ThumbnailCopRobber from '../resources/images/press/small__cop_robber.png';
import ThumbnailPromo from '../resources/images/press/small__roco_promotion.png';

import Logo from '../resources/images/logo_transparent_purple.png';
import Advertising from '../resources/images/press/background_illustration.png';
import CopRobberBig from '../resources/images/press/cop_robber.png';

import TeamBlue from '../resources/images/press/team_blue.png';

const GoogleAnalyticsTrackingId = "G-YV43WSXVYV";
ReactGA.initialize(GoogleAnalyticsTrackingId);

const useAnalyticsEventTracker = (category="Roco-Web") => {
    const eventTracker = (action = "test action", label = "test label") => {
        ReactGA.event({category, action, label});
    }
    return eventTracker;
}


const Navigation = ({setOpen, headerRef}: {setOpen(a: boolean): void, headerRef: RefObject<HTMLDivElement>}) => {
    const gaEventTracker = useAnalyticsEventTracker();
    return (
        <nav className='nav'>
            <ul className='nav-list nav-list-mobile'>
                <li className='nav-item'>
                    <div className='mobile-menu' onClick={() => {
                        headerRef.current?.classList.toggle('active');
                    }}>
                        <span className='line line-top'></span>
                        <span className='line line-bottom'></span>
                    </div>
                </li>
            </ul>
            <ul className='nav-list nav-list-larger'>
                <li className='nav-item'>
                    <Link className='nav-link' to="/" onClick={() => {
                        headerRef.current?.classList.remove('active');
                    }}>Home</Link>
                </li>
                <li className='nav-item'>
                    <Link className='nav-link' to="/press" onClick={() => {
                        headerRef.current?.classList.remove('active');
                    }}>Press</Link>
                </li>
                <li className='nav-item'>
                    <a className='nav-link' href='#' onClick={() => {
                        headerRef.current?.classList.remove('active');
                        setOpen(true);
                    }}>Trailer</a>
                </li>
                <li className='nav-item'>
                    <Link className='nav-link' to="/terms" onClick={() => {
                        headerRef.current?.classList.remove('active');
                    }}>Privacy</Link>
                </li>
                <li className='nav-item nav-link-socials'>
                    <a className='nav-link nav-link-discord nav-link-mobile' href='https://discord.gg/svueseMwWd' target='_blank' onClick={() => gaEventTracker('discord')} />
                    <a className='nav-link nav-link-twitter nav-link-mobile' href='https://twitter.com/RocoTheGame' target='_blank' onClick={() => gaEventTracker('twitter')} />
                    <a className='nav-link nav-link-facebook nav-link-mobile' href='https://www.facebook.com/RocoTheGame' target='_blank' onClick={() => gaEventTracker('facebook')} />
                </li>
            </ul>
        </nav>
    );
}

const Home = () => {
    const sketchImageRef = useRef<HTMLDivElement>(null);

    window.addEventListener("scroll", () => {
        const checkPointPadding = 380;
        const currentScroll = window.scrollY;
        let opacity;
        if (currentScroll <= checkPointPadding) {
            opacity = 1;
        } else {
            opacity = 0;
        }

        if (sketchImageRef.current) sketchImageRef.current.style.opacity = `${opacity}`;
    });

    return(
        <>
            <video className='background' src={BackgroundVideo} loop autoPlay muted playsInline />
            <div className='overlay blend-mode' />
            <div className='overlay' />
            <section className='section headline'>
                <div className='container' style={{display: 'flex', justifyContent: 'center'}}>
                    <div>
                        <div className='title'>
                            <h2 className='title-heading'>Robbers and Cops</h2>
                            <p className='title-sub-note'>This game is a complete reinvention of a childhood tag game called “Cops and Robbers”. Roco is a fully modernised live action cooperative outdoor adventure, which is playable on every mobile device</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <a href='https://apps.apple.com/us/app/roco-robbers-and-cops/id1605172872' target='_blank' className='cta-button cta-button-apple' />
                            <a href='https://play.google.com/store/apps/details?id=com.roco.application' target='_blank' className='cta-button cta-button-google' />
                        </div>
                    </div>
                </div>
            </section>
            <section className='section game'>
                <div className='container' style={{display: 'flex'}}>
                    <div>
                        <div className='title'>
                            <h2 className='title-heading'>About the Game</h2>
                            <p className='title-sub-note'>The game is about Cops versus Robbers, loaded with many additional features supported by the new generation of smartphones
                            </p>
                        </div>
                        <div className='game game-features'>
                            <ul>
                                <li>
                                    Eight unique player characters with special abilities
                                </li>
                                <li>
                                    Player Ranking
                                </li>
                                <li>
                                    Booster Items to improve your team fights
                                </li>
                                <li>
                                    Different Game Types (Classic, Battle Royal)
                                </li>
                                <li>
                                    Different Lobby Types (Training, Private)
                                </li>
                                <li>
                                    In-Game Messaging System
                                </li>
                            </ul>
                            <div className='img-sketch-wrapper'>
                                <div style={{backgroundImage: `url(${CopRobber})`, width: '29rem'}} />
                                <div ref={sketchImageRef} style={{backgroundImage: `url(${Sketch})`, width: '29rem', transition: 'opacity 300ms ease-out'}} />

                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className='section howto'>
                <div className='container'>
                    <div className='title'>
                        <h2 className='title-heading'>Howto Play</h2>
                        <p className='title-sub-note'>Pick your individual character, configure your game session, select appropriate booster items, start playing. Robbers will be detected if a Cop will find them. Robbers are dropping jewels during their escape from the cops. View the game summary, your player statistics and receive awesome rewards</p>
                    </div>
                    <div className='wireframes'>
                        <img src={Wireframes} />
                    </div>
                </div>
            </section>
            <section className='section items'>
                <div className='container'>
                    <div className='title'>
                        <h2 className='title-heading'>Booster Items</h2>
                        <p className='title-sub-note'>Equip your player with individual items to support your team and improve your team fights</p>
                    </div>
                    <div className='item-wrapper'>
                        <div className='item-block'>
                            <div className='item-single'>
                                <div><img src={ItemSixthSense} className='item sixth-sense' /></div>
                                <div>Extended detection radius for your player. Enables vibration support for nearby enemies</div>
                            </div>
                            <div className='item-single'>
                                <div><img src={ItemTimeTravel} className='item time-travel' /></div>
                                <div>Powerful item to jump back in time and manipulate the game clock. Buy more time for your team</div>
                            </div>
                        </div>
                        <div className='item-block'>
                            <div className='item-single'>
                                <div><img src={ItemCommunicationJammer} className='item communication-jammer' /></div>
                                <div>Start a powerful electromagnetic pulse. You can disable the enemy communication</div>
                            </div>
                            <div className='item-single'>
                                <div><img src={ItemStableBag} className='item stable-bag' /></div>
                                <div>Improved version of your jewel bag. Dropping jewels on player movement will be decreased</div>
                            </div>
                        </div>
                        <div className='item-block'>
                            <div className='item-single'>
                                <div><img src={ItemDoubleValue} className='item stable-bag' /></div>
                                <div>Every jewel you are collecting will be accounted by the factor of two</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='section lobby-types'>
                <div className='container'>
                    <div className='title'>
                        <h2 className='title-heading'>Lobby Types</h2>
                        <p className='title-sub-note'>We provide two different lobby types which are designed for single and multiplayer purposes</p>
                    </div>
                    <div className='item-wrapper'>
                        <div className='item-single'>
                            <div>Training</div>
                            <div className='item-content'>
                                <div><img src={CharacterLewis} /></div>
                                <div>On this mode you can improve your team fights, tactics and item design. In this mode you will play against computer controlled robbers</div>
                            </div>
                        </div>
                        <div className='item-single'>
                            <div>Private</div>
                            <div className='item-content'>
                                <div><img src={CharacterLewisAndRusty} /></div>
                                <div>This mode will give you the possibility to play against your friends, only invited people can join our private game session</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='section characters'>
                <div className='container'>
                    <div className='title'>
                        <h2 className='title-heading'>Characters</h2>
                        <p className='title-sub-note'>We provide eight individually designed characters which represents either a Cop or a Robber. Each character has its own abilities and can be selected strategically for every game round</p>
                    </div>
                    <div className='title-sub-heading' style={{marginTop: '3rem', textAlign: 'center'}}>Cops</div>
                    <div className='item-wrapper'>
                        <div className='item-single cop'>
                            <div>Lewis</div>
                            <div className='item-content'>
                                <img src={CharacterLewis} />
                            </div>
                            <div>Detective Lewis will let you earn extra score bonus</div>
                        </div>
                        <div className='item-single cop'>
                            <div>Foxy</div>
                            <div className='item-content'>
                                <img src={CharacterFoxy} />
                            </div>
                            <div>Foxy's wisdom will give you an extra score bonus</div>
                        </div>
                        <div className='item-single cop'>
                            <div>Abigail</div>
                            <div className='item-content'>
                                <img src={CharacterAbigail} />
                            </div>
                            <div>Detective Abigail will let you earn extra jewels</div>
                        </div>
                        <div className='item-single cop'>
                            <div>Douglas</div>
                            <div className='item-content'>
                                <img src={CharacterDouglas} />
                            </div>
                            <div>
                                Officer Douglas will let you earn extra jewels
                            </div>
                        </div>
                    </div>
                    <div className='title-sub-heading' style={{marginTop: '3rem', textAlign: 'center'}}>Robbers</div>
                    <div className='item-wrapper'>
                        <div className='item-single robber'>
                            <div>Rusty</div>
                            <div className='item-content'>
                                <img src={CharacterRusty} />
                            </div>
                            <div>
                                Street boy Rusty will let you earn an extra score bonus
                            </div>
                        </div>
                        <div className='item-single robber'>
                            <div>Vally</div>
                            <div className='item-content'>
                                <img src={CharacterVally} />
                            </div>
                            <div>
                                Engineer Vally will let you earn extra jewels
                            </div>
                        </div>
                        <div className='item-single robber'>
                            <div>Tess</div>
                            <div className='item-content'>
                                <img src={CharacterTess} />
                            </div>
                            <div>
                                Tess's dexterity will let you earn extra jewels
                            </div>
                        </div>
                        <div className='item-single robber'>
                            <div>Chester</div>
                            <div className='item-content'>
                                <img src={CharacterChester} />
                            </div>
                            <div>
                                Chester the musician will let you earn extra score bonus
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='section starter-package'>
                <div className='container'>
                    <div className='title'>
                        <h2 className='title-heading'>Starter Package</h2>
                        <p className='title-sub-note'>Every supporter receives one of our awesome starter package</p>
                    </div>
                    <div className='item-wrapper'>
                        <div className='item-block'>
                        <div className='item-single'>
                            <div><img src={StarterPackageGem} className='item' /></div>
                            <div>You will receive additional 500 Jewels on the first installation of Roco</div>
                        </div>
                        <div className='item-single'>
                            <div><img src={StarterPackageScore} className='item' /></div>
                            <div>You will receive additional 1000 Score Points on the first installation of Roco</div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
}

const Privacy = () => {
    return (
        <section className='section privacy'>
            <div className='container'>
                <div className='title policy'>
                    <h2 className='title-heading'>Privacy Policy</h2>
                    <h3 className='title-sub-heading privacy'>21th, Okt 2023</h3>
                    <p className='title-sub-note'>Privacy Policy
                        This page is used to inform website visitors regarding our policies with the collection,
                        use, and disclosure of Personal Information if anyone decides to use Robbers and Cops.</p>
                    <p className='title-sub-note'>
                        If you choose to use this app, then you agree to the collection and use of information in relation to this policy.
                        The Personal Information that I collect is used for providing and improving the Service. I will not use or share your information with anyone except as described in this Privacy Policy.
                    </p>
                    <h3 className='title-sub-heading privacy'>Information Collection and Use</h3>
                    <p className='title-sub-note'>For a better experience, while using our Service, the app does use third party services that may collect information used to identify you.</p>
                    <p className='title-sub-note'>Link to privacy policy of third party service providers used by the app<a href='https://policies.google.com/privacy' target='_blank'>Google Play Services</a><a href='https://apple.com/legal/privacy' target='_blank'>Apple Store Services</a></p>
                    <p className='title-sub-note'>We only collect and store your account id, we not store your username or passwort.</p>
                    <p className='title-sub-note'>To delete your account either use the in app account deletion functionality or write an email to <a href='mailto:mail@roco-game.com'>mail@roco-game.com</a></p>
                    <h3 className='title-sub-heading privacy'>Log Data</h3>
                    <p className='title-sub-note'>We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data.
                        This Log Data may include information such as your device Internet Protocol (“IP”) address,
                        device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.</p>
                    <h3 className='title-sub-heading privacy'>Links to other Sites</h3>
                    <p className='title-sub-note'>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites.
                        We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
                    <h3 className='title-sub-heading privacy'>Security</h3>
                    <p className='title-sub-note'>We seek to use appropriate organizational, technical and administrative measures to protect personal data within our organization.
                        Unfortunately, no transmission or storage system can be guaranteed to be completely secure, and transmission of information via the internet is not completely secure.
                        If you have reason to believe that your interaction with us is no longer secure, please immediately notify us of the problem by contacting us using the details in the Contact Information section below.</p>
                    <h3 className='title-sub-heading privacy'>Policy Changes</h3>
                    <p className='title-sub-note'>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.</p>
                    <h3 className='title-sub-heading privacy'>Contact</h3>
                    <p className='title-sub-note'> If you have any questions or suggestions about the Privacy Policy, do not hesitate to contact us at <a href='mailto:mail@roco-game.com'>mail@roco-game.com</a></p>
                </div>
            </div>
        </section>
    );
}

const Press = () => {
    const gaEventTracker = useAnalyticsEventTracker();
    return (
        <section className='section press'>
            <div className='container'>
                <div className='title policy'>
                    <h2 className='title-heading'>Press Kit</h2>
                    <h3 className='title-sub-heading press'>Assets</h3>
                    <div>
                        <div className='item-container press'><div>Logo</div><img src={ThumbnailLogo} /><a href={Logo} onClick={() => gaEventTracker('download-logo')} download>Download</a></div>
                        <div className='item-container press'><div>Main Characters</div><img src={ThumbnailCopRobber} /><a href={CopRobberBig} onClick={() => gaEventTracker('download-main-characters')} download>Download</a></div>
                        <div className='item-container press'><div>Background Illustration</div><img src={ThumbnailAdvertising} /><a href={Advertising} onClick={() => gaEventTracker('download-illustration')} download>Download</a></div>
                        <div className='item-container press'><div>Promotion Slides</div><img src={ThumbnailPromo} /><a href={require("../resources/images/press/roco_promotion.pdf")} onClick={() => gaEventTracker('download-promo')} target='_blank'>Download</a></div>
                    </div>
                    <h3 className='title-sub-heading press'>Team</h3>
                    <p className='title-sub-note'>Lead Developer Matthias Huber is a german indie game developer started building mobile games ten years ago. This project is a collaboration of awesome people working on different domains containing Sound-FX, Video, Arts, Development and Infrastructure</p>
                    <div className='press team-wrapper'><img src={TeamBlue} /></div>
                    <h3 className='title-sub-heading press'>Contact</h3>
                    <p className='title-sub-note'> If you have any questions or suggestions about the project, do not hesitate to contact us at <a href='mailto:mail@roco-game.com'>mail@roco-game.com</a></p>
                </div>
            </div>
        </section>
    );
}

const DonationComplete = () => {
    return (
        <section className='section donation-complete'>
            <div className='container'>
                <div className='title donation-complete'>
                    <h2 className='title-heading'>Thank you for your Donation</h2>
                    <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                        <div className='title-sub-note'>
                            <p>Thank you for your support. Join our discord server to get your exclusive Roco supporter role. We will immediately deliver your Roco starter package into your mailbox</p>
                            <p>You can return to our homepage by <a href="/">clicking here</a></p>
                        </div>
                        <div><img src={IconBird} /></div>
                    </div>
                </div>
            </div>
        </section>
    );
}

const NoMatch = () => {
    return (
        <section className='section nomatch'>
            <div className='container'>
                <div className='title nomatch'>
                    <h2 className='title-heading'>Not Found</h2>
                    <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                        <div className='title-sub-note'>
                        <p>We couldn't find the page you were looking for. This is either because:</p>
                        <ul>
                            <li>There is an error in the URL entered into your web browser. Please check the URL and try again.</li>
                            <li>The page you are looking for has been moved or deleted.</li>
                        </ul>
                            <p>You can return to our homepage by <a href="/">clicking here</a></p>
                        </div>
                        <div><img src={IconBird} /></div>
                    </div>
                </div>
            </div>
        </section>
    );
}

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}


const App = () => {
    const [ isOpen, setOpen ] = useState(false);
    const headerRef = useRef<HTMLDivElement>(null);
    const gaEventTracker = useAnalyticsEventTracker();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Page View" });
    }, [])

    return (
      <Router>
          <ScrollToTop />
          <div>
              <header ref={headerRef}>
                  <div className='container container--logo'>
                      <div className='logo' />
                      <div style={{width: '80rem'}} />
                  </div>
                  <div className='container container--nav'>
                      <Navigation setOpen={setOpen} headerRef={headerRef} />
                  </div>
                  <div className='container container--social-links'>
                      <div style={{flexBasis: '50%'}} />
                      <div style={{width: '30rem'}} />
                      <ul className='nav-list' style={{zIndex: 1, position: 'relative'}}>
                          <li className='nav-item'>
                              <a className='nav-link nav-link-discord' href='https://discord.gg/svueseMwWd' target='_blank' onClick={() => gaEventTracker('discord')} />
                          </li>
                          <li className='nav-item'>
                              <a className='nav-link nav-link-twitter' href='https://twitter.com/RocoTheGame' target='_blank' onClick={() => gaEventTracker('twitter')} />
                          </li>
                          <li className='nav-item'>
                              <a className='nav-link nav-link-facebook' href='https://www.facebook.com/RocoTheGame' target='_blank' onClick={() => gaEventTracker('facebook')} />
                          </li>
                      </ul>
                  </div>

              </header>
              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/press" element={<Press />} />
                  <Route path="/terms" element={<Privacy />} />
                  <Route path="/completed" element={<DonationComplete />} />
                  <Route path="*" element={<NoMatch />} />
              </Routes>
              <footer>
                  <div className='footer-content'>
                      <div className='footer-content--wrapper'>
                          <div className='mail-subscription'>
                            <div className='mail-subscription--title'>Subscribe</div>
                            <div className='mail-subscription--subtitle'>You will receive all product updates, upcoming features, development insights and release dates</div>
                            <MailchimpSubscribe
                                url='https://huber-its.us13.list-manage.com/subscribe/post?u=91e362e80321f7c8cf657e37d&id=65ac8e8c23'
                            />
                          </div>
                          <div className='donation'>
                              <div className='donation--title'>Donate</div>
                              <div className='donation-wrapper'>
                                  <div className='donation--subtitle'>If you think this project is pretty cool we highly appreciate your donation. Every supporter will get some cool starter package immediately</div>
                                  <form action="https://www.paypal.com/donate" method="post" target="_top">
                                      <input type="hidden" name="hosted_button_id" value="68JWJ5DTSQCAN" />
                                      <input className='donation--button' type="image" src={DonatePaypal} name="submit" title="Donate for Robbers and Cops" />
                                  </form>
                              </div>
                          </div>
                      </div>
                      <div style={{marginTop: '1rem'}}>
                          <div className='footer-legal-copyright'>
                              Copyright © {new Date().getFullYear()} MH. All rights reserved.
                          </div>
                          <div className='footer-legal-links'>
                              <a href='mailto:mail@roco-game.com'>Contact Us</a>
                              <Link to='terms'>Privacy Policy</Link>
                          </div>
                      </div>
                  </div>
              </footer>
              <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="_ZsZX09f4Fw" onClose={() => setOpen(false)} />
          </div>
      </Router>
    );
}

export default App;
